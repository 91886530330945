<template>
  <div class="row mt-0 px-3 justify-center">
    <div class="col-sm-12 col-md-5 col-lg-3 w-100">
      <h2 v-text="section.name" class="mb-3"></h2>
      <div
        class="d-flex flex-column mb-5"
        v-for="(item, index) in items"
        :key="index"
      >
        <v-card>
          <div class="d-flex">
            <span v-text="item.name" class="my-auto ml-3"></span>
            <v-btn
              class="py-2 ml-auto my-1 mr-1"
              color="secondary"
              @click="redirectToLink(item.webSiteUrl)"
              >VER</v-btn
            >
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SecondSection",
  props: {
    section: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    redirectToLink(link) {
      if (link) {
        let url = link.includes("http") ? link : "http://" + link;
        window.open(url, "_blank");
      }
    },
  },
  computed: {
    items() {
      return this.section?.items ?? [];
    },
  },
};
</script>
