<template>
  <div class="pa-3">
    <div class="d-flex justify-center">
      <div class="col-sm-12 col-md-5 col-lg-3 d-flex flex-column">
        <h2 v-text="internalName"></h2>
        <p class="my-0">Hoy es <strong v-text="dateCurrentFormat"></strong></p>
      </div>
    </div>
    <div class="d-flex justify-center">
      <div class="col-sm-12 col-md-5 col-lg-3 py-0 d-flex justify-center">
        <v-img :src="internalImageUrl" />
      </div>
    </div>
    <first-section
      :section="firstSection"
      v-if="!isEmptyFirstSection"
    ></first-section>
    <second-section
      :section="secondSection"
      v-if="!isEmptySecondSection"
    ></second-section>
  </div>
</template>

<script>
import loaderMixin from "@/mixins/loaderMixin";
import notificationMixin from "@/mixins/notificationMixin";
import { BASE_IMAGES_URL } from "@/constants/ServicesConstants";
import moment from "moment";

import FirstSection from "./components/FirstSection.vue";
import SecondSection from "./components/SecondSection.vue";

export default {
  name: "GeneralInformation",
  mixins: [loaderMixin, notificationMixin],
  components: { FirstSection, SecondSection },
  data() {
    return {
      internalImageUrl: null,
      firstSection: {},
      secondSection: {},
    };
  },
  methods: {
    async getGeneralInformation() {
      try {
        let response = await this.$axiosApiMobile.get(
          `/general-information?fairId=${this.fair.id}`
        );
        if (response.data?.length == 2) {
          this.firstSection = response.data[0];
          this.secondSection = response.data[1];
        }
      } catch (e) {
        if (e.response?.status >= 500) {
          this.showError();
        }
      }
    },
    async getInternalImage() {
      try {
        let response = await this.$axiosApiMobile.get(
          `/fairs/${this.fair.id}/appModules/INF/internalImageUrl`
        );
        if (response.data) {
          this.internalImageUrl = `${BASE_IMAGES_URL}${response.data}`;
        }
      } catch (error) {
        this.errorHandler(error?.response?.status);
      }
    },
  },
  computed: {
    fair() {
      return this.$store.getters["fairs/fair"];
    },
    dateCurrentFormat() {
      return moment().format("dddd, D ## MMMM").replace("##", "de");
    },
    isEmptyFirstSection() {
      return this.firstSection?.items?.length ? false : true;
    },
    isEmptySecondSection() {
      return this.secondSection?.items?.length ? false : true;
    },
    internalName() {
      return (
        this.fair?.app_modules?.filter((item) => item.modules == "INF")[0]
          ?.internalName ?? "Información General"
      );
    },
  },
  async created() {
    moment.locale("es");
    this.showLoader();
    await this.getInternalImage();
    await this.getGeneralInformation();
    this.hideLoader();
  },
};
</script>
