<template>
  <div class="row px-3 mt-2 justify-center">
    <div class="col-sm-12 col-md-5 col-lg-3 w-100">
      <h2 v-text="section.name" class="mb-3"></h2>
      <div
        class="d-flex flex-column mb-5"
        v-for="(item, index) in items"
        :key="index"
      >
        <h4 v-text="item.name" class="mb-1"></h4>
        <span
          v-for="(hour, i) in item.hours"
          v-text="getRangeHours(hour)"
          :key="i"
          class="ml-3"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "FirstSection",
  props: {
    section: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    getRangeHours(hour) {
      return `${moment(hour.initialHour, "hh:mm:ss").format(
        "hh:mm A"
      )} - ${moment(hour.finalHour, "hh:mm:ss").format("hh:mm A")}`;
    },
  },
  computed: {
    items() {
      return this.section?.items ?? [];
    },
  },
};
</script>
